<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Cadastro de Metas</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            <template v-slot:top>
              <BrToolBar
                @search="buscar"
                :configFilter="{
                  listaSelect: item,
                  getItens: getItens,
                  jsonData: false,
                  isStatus,
                }"
                :labelTextField="'Busca por:'" />
            </template>

            <template v-slot:item.refDate="{ item }">
               {{ item.refDate | datas('DD/MM/YYYY')}}
            </template>

            <template v-slot:item.salesReadjustmentIndex="{ item }">
               {{ item.salesReadjustmentIndex | percentage}}
            </template>

            <template v-slot:item.debitIndex="{ item }">
               {{ item.debitIndex | percentage}}
            </template>

            <template v-slot:item.minimumResellerSalesValue="{ item }">
               {{ item.minimumResellerSalesValue | currency }}
            </template>

            <!-- <template v-slot:item.status="{ item }">
              <v-chip style="cursor:pointe" @click="updateStatusAproved(item)" v-if="permiteAcao($route, 'edit')"  :color="getColorSituacao(item.status)" dark>{{item.status === 'P' ? 'Pendente': 'Aprovado' }}</v-chip>
            </template> -->

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete')"
                class="mr-2"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Excluir
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>

        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import listagemTables from '@/views/mixins/listagemTables'
export default {

  name: 'CadastroMetasVendas',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    activeModal: false,
    headers: [
      {align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      {align: 'start', class: 'table-header', text: 'Referência', value: 'refDate' },
      {align: 'start', class: 'table-header', text: 'INPC', value: 'salesReadjustmentIndex' },
      {align: 'start', class: 'table-header', text: 'Débito', value: 'debitIndex' },
      {align: 'start', class: 'table-header', text: 'Mínimo Vendas', value: 'minimumResellerSalesValue' },
      // {align: 'start', class: 'table-header', text: 'Status', value: 'status' },
      {align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),
  computed: {
    ...mapGetters('listarMetas', ['listaItens', 'totalItens', 'item']),
    ...mapGetters('roles', ['permiteAcao']),
  },

  mounted () {
    // this.obterItensSelect()
  },

  methods: {
    ...mapActions('listarMetas', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens', 'approvedStatus']),
    updateStatusAproved(v) {

      // verifica se a rota meta status é abilitada
      if (this.permiteAcao({path:'/cadastros/metas/status'}, 'edit') === false) return false

      let dados = Object.assign({}, v)
      if (dados.status === 'V') return false

      dados.status = 'V'
      this.loadingTable = true

      this.approvedStatus(dados).then(() => {
        this.msgAll('Status alterado com sucesso!')
        this.getItens().then(() => {

        }).finally(() => this.loadingTable = false )
      }).catch(err => {
        this.msgAll( err.message ,true)
        this.loadingTable = false
      })
      
    },
    getColorSituacao(v) {
      if(v === 'P') return 'orange'
      return 'green'
    }
  }
}
</script>

<style lang="scss">
  @import './../../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
